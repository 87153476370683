$img-opacity: .7;

#Bg {
 .dev-bg {
  position: fixed;
  z-index: 100;
  bottom: 3px;
  right: 0;
  span {
   line-height: 1.3em;
   padding: 0 .3em;
   margin: .3em;
   border-radius: .3em;
  }
  .id {
   background: red;
  }
  .level {
   background: yellow;
  }
 }

 .bg-img {
  position: fixed;
  z-index: -2;
  img {
   opacity: $img-opacity;
   display: inline-block;
  }
 }
 .bg-img-w {
  display: flex;
  align-items: center;
  img {
   width: 100vw;
   height: auto;
  }
 }
 .bg-img-h {
  width: 2000px;
  left: 50%;
  margin-left: -1000px;
  text-align: center;
  img {
   height: 100vh;
   width: auto;
  }
 }

 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
   display: block;
   padding: 0;
   margin: 0;
   position: fixed;
   z-index: -3;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   opacity: 0;
   transition: opacity 5s linear;
   .bg-img {
    position: fixed;
    z-index: -2;
    img {
     opacity: $img-opacity;
     display: inline-block;
    }
   }
   .bg-img-w {
    display: flex;
    align-items: center;
    img {
     width: 100vw;
     height: auto;
    }
   }
   .bg-img-h {
    width: 2000px;
    left: 50%;
    margin-left: -1000px;
    text-align: center;
    img {
     height: 100vh;
     width: auto;
    }
   }
  }
  li.active {
   opacity: 1;
  }
 }
 ul::after {
  content: "";
  display: block;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  height: 264px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
 }

 ul.prev-by-bgi {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  li {
   display: block;
   padding: 0;
   margin: 0;
   position: fixed;
   z-index: -3;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   opacity: 0;
   transition: opacity .2s linear;
  }
 }
}

@media (max-width: $mobile-width), (max-height: $mobile-height) {
 #Bg {
  ul::after {
   display: none;
  }
 }
}

@media print {
 #Bg {
  display: none;
 }
}