.TopicsPage {
 overflow-x: hidden;
}

.Topic {
 position: fixed;
 z-index: 99;
 left: 0;
 top: 0;
 right: 0;
 bottom: 0;
 background: #00000099;
 .topicPopup {
  background: rgba(255,255,255,.8);
  padding: 2em;
  position: absolute;
  z-index: 100;
  left: 200px;
  top: 60px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .3);
  h1 {}
  p {}
 }
}

@media (min-width: $mobile-width+1) and (min-height: $mobile-height+1) {
 .Topics {
  min-height: 100vh;
  width: 100%;
  .page {
   background: hsla(0, 0%, 100%, .06);
   backdrop-filter: blur(8px);
  }
 }
}
@media (max-width: $mobile-width), (max-height: $mobile-height) {
 .Topics {
  display: block;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  background: hsla(0, 0%, 100%, .06);
  backdrop-filter: blur(8px);
  .page {
   padding: 80px 24px 32px !important;
   height: 100%;
   max-width: 100%;
   box-sizing: border-box;
  }
  .footer {
   display: none;
  }
 }
}
