.Menu {
 position: fixed;
 z-index: 100;
 left: 0;
 top: 0;
 bottom: 0;
 width: $menu-desktop-size;
 background: rgba(17, 17, 17, 0.60);
 backdrop-filter: blur(10px);
 visibility: hidden;
 opacity: 0;
 transition: visibility 0s $menu-trigger-time, opacity $menu-trigger-time linear;
 .head-box {
  position: relative;
  padding: 15px 12px 15px 48px;
  font-family: 'isocteurregular', sans-serif;
  font-size: 17px;
  line-height: 18px;
  button.x {
   @extend %svg-x;
  }
 }
 .info-box {
  font-family: 'PT Sans', sans-serif;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.40);
  line-height: 16px;
  padding: 0 16px 44px;
  span {
   color: inherit;
  }
  a {
   color: inherit;
   text-decoration: none;
   transition: .1s linear;
  }
  a:hover {
   color: white;
  }
  .links {
   span::before {
    content: ",";
    margin-right: .4em;
   }
   span:first-child::before {
    content: "";
    margin-right: 0;
   }
  }
 }
 .link-box {
  max-height: calc(100vh - 276px);
  overflow: auto;
  padding: 0 16px;
  a {
   position: relative;
   display: block;
   font-size: 15px;
   color: rgba(255, 255, 255, 0.40);
   line-height: 32px;
   padding: 0 16px 0 24px;
   text-decoration: none;
   transition: color .1s linear, background-color .1s linear, background-image 0s .05s;
  }
  a:hover {
   color: #fff;
  }
  a.active {
   @extend %svg-v-selected-white;
   background-repeat: no-repeat;
   background-position: 0 9px;
   color: #fff;
  }
  a.active:hover {
  }
  a.link-home {
   display: flex;
   justify-content: space-between;
   label {
   }
   span {
    opacity: .4;
   }
  }
  .divide {
   height: 1px;
   background: rgba(255, 255, 255, 0.20);
   margin: 8px 4px;
  }
 }
 .contacts-box {
  display: block;
  .buttons {
   position: fixed;
   left: 24px;
   right: 24px;
   height: 32px;
   bottom: -64px;
   transition: bottom $menu-trigger-time linear;
   display: flex;
   border-top: 1px rgba(255, 255, 255, 0.20) solid;
   padding: 16px 0 0;
   .button {
    box-sizing: border-box;
    width: 50%;
    height: 32px;
    border-left: 1px rgba(255, 255, 255, 0.20) solid;
    text-align: center;
    a {
     display: inline-block;
     width: 32px;
     height: 32px;
     background-position: center center;
     background-repeat: no-repeat;
     background-size: 24px 24px;
     opacity: .2;
     transition: opacity .15s linear;
     label {
      display: none;
     }
    }
    a:hover {
     opacity: 1;
    }
   }
   .button:first-child {
    border-left: 0;
   }
   .button-phone {
    left: 56px;
    a {
     @extend %svg-phone-16-white;
     transform: rotate(-90deg);
    }
   }
   .button-mail {
    right: 56px;
    a {
     @extend %svg-mail-white;
    }
   }
  }
 }
}
body.menu-active {
 .Menu {
  visibility: visible;
  opacity: 1;
  transition: opacity $menu-trigger-time linear;
  .contacts-box {
   .buttons {
    bottom: 24px;
   }
  }
 }
}

@media (min-width: ($mobile-menu-width + 1)) {
 .Menu {
  .contacts-box {
   .buttons {
    right: auto;
    width: $menu-desktop-size - 48px;
   }
  }
 }
 body.menu-active {
  .Page {
   left: $menu-desktop-size;
  }
 }
}

@media (max-width: $mobile-menu-width), (max-height: $mobile-height) {
 .Menu {
  right: 0;
  width: auto;
  .contacts-box {
   display: block;
  }
 }
}

// fix height for iphone 5
@media (max-height: 340px) {
 .Menu {
  .contacts-box {
   .buttons {
    bottom: 32px !important;
   }
  }
 }
}


