.About {
 display: flex;
 align-items: center;

 .page {
  position: relative;
  max-width: 536px;
  margin: 0 auto;
  padding: 32px 40px;
  overflow: hidden;

  .head {
   margin: 0 auto;
   display: flex;
   align-items: center;
   .img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: rgba(0, 0, 0, .2);

    img {
     display: block;
     width: 48px;
     height: 48px;
     margin-top: 8px;
     margin-left: 8px;
    }
   }
   .title {
    color: #FFFFFF;
    margin-left: 16px;
    .name {
     opacity: 0.6;
     font-size: 17px;
     font-weight: 700;
     letter-spacing: 0;
     line-height: 24px;
    }
    .slogan {
     opacity: 0.2;
     font-size: 13px;
     letter-spacing: 0;
     line-height: 16px;
    }
   }
  }
  .body {
   padding: 16px 0 24px;
   h1 {
    opacity: 0.6;
    font-size: 17px;
    font-weight: 700;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 24px;
    padding: 0 0 24px;
    margin: 0;
   }
   p {
    opacity: 0.6;
    font-size: 15px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 18px;
    margin: 0;
    padding: 0 0 16px;
   }
   address {
    opacity: 0.6;
    font-size: 13px;
    font-style: normal;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 16px;
    margin: 16px 0 0;
    padding: 0;
   }
   address.address {
    @extend %svg-location-16-white;
    background-repeat: no-repeat;
    padding-left: 28px;
   }
   address.phone {
    @extend %svg-phone-16-white;
    background-repeat: no-repeat;
    padding-left: 28px;
   }
  }
  .footer {
   opacity: 0.2;
   font-size: 13px;
   color: #FFFFFF;
   letter-spacing: 0;
   line-height: 16px;
  }

 }
}

@media (min-width: $mobile-width+1) and (min-height: $mobile-height+1) {
 .About {
  min-height: 100vh;
  min-width: 100vw;
  .page {
   background: hsla(0, 0%, 100%, .06);
   backdrop-filter: blur(8px);
  }
 }
}
@media (max-width: $mobile-width), (max-height: $mobile-height) {
 .About {
  display: block;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  background: hsla(0, 0%, 100%, .06);
  backdrop-filter: blur(8px);
  .page {
   padding: 80px 24px 32px !important;
   height: 100%;
   max-width: 100%;
   box-sizing: border-box;
  }
  .footer {
   display: none;
  }
 }
}

@-moz-document url-prefix() {
 .About {
  .page {
   background: $color-firefox-layout-bg;
  }
 }
}
