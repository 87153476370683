.GridBox {
 padding: $layout-padding;
 padding-left: 0;
 padding-right: 0;
 box-sizing: border-box;
 position: absolute;
 left: 0;
 top: 0;
 right: 0;
 min-height: 100vh;
 transition: all 400ms linear;
 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
   padding: 0;
   margin: 4px 0;
   background: rgba(17,17,17,.4);
   backdrop-filter: blur(6px);
   a {
    text-decoration: none;
   }
   .grid-item {
    display: flex;
    position: relative;
    height: 72px;
    overflow: hidden;
    .img {
     display: none;
    }
    .ico-folder {
     @extend %svg-folder;
    }
    .ico-i {
     @extend %svg-i;
    }
    .ico {
     display: block;
     opacity: .4;
     width: 24px;
     height: 24px;
    }
    .txt {
     position: absolute;
     z-index: 3;
     left: 0;
     bottom: 0;
     right: 0;
     padding: 11px 24px 15px;
     .name {
      font-size: 15px;
      color: rgba(255,255,255,0.80);
      letter-spacing: 0;
      line-height: 24px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
     }
     .description {
      margin-top: 6px;
      font-size: 13px;
      color: rgba(255,255,255,0.40);
      letter-spacing: 0;
      line-height: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
     }
    }
   }
   .grid-item:hover {
   }
   .grid-item-nav {
    .txt {
     .icon {
      @extend %svg-back;
      width: 16px;
      height: 16px;
      opacity: .4;
      position: absolute;
      bottom: 15px;
     }
    }
   }
   .grid-item-nav-prev {
    .txt {
     padding-left: 40px;
     .icon {
      left: 12px;
     }
    }
   }
   .grid-item-nav-next {
    .txt {
     padding-right: 40px;
     .icon {
      transform: rotate(180deg);
      right: 12px;
     }
    }
   }
  }
  li:hover {

  }
 }
}

.GridBox.at-left {
 left: -101%;
 right: 101%;
}
.GridBox.at-right {
 left: 101%;
 right: -101%;
}

.GridDots {
 display: none;
 position: fixed;
 left: 0;
 right: 0;
 bottom: 20px;
 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  height: 7px;
  li {
   padding: 0;
   margin: 0 5px;
   display: inline-block;
   span {
    display: block;
    background: #fff;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    opacity: .2;
    transition: all 400ms linear;
   }
   span.sel {
    opacity: .8;
   }
  }
  li:hover {
   span {
    opacity: 1;
   }
  }
 }
}

@media (max-width: $mobile-width), (max-height: $mobile-height) {
 .GridBox {
  .grid-item {
   .txt {
    .ico {
     position: absolute;
     right: 24px;
     top: 50%;
     margin-top: -12px;
    }
    .with-ico {
     padding-right: 48px;
    }
   }
  }
 }
}
@media (min-width: $mobile-width+1) and (min-height: $mobile-height+1) {
 .GridBox {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  ul {
   display: flex;
   flex-wrap: wrap;
   width: 448px;
   max-height: 672px;
   li {
    margin: 12px;
    .grid-item {
     width: 200px;
     height: 200px;
     .img {
      display: block;
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: .6;
      filter: blur(6px);
      transition: all .2s linear;
      img {
       display: block;
       width: 100%;
       height: 100%;
      }
     }
     .txt {
      opacity: 1;
      transition: all .2s linear;
      .ico {
       margin-bottom: 14px;
      }
      .name {
       white-space: normal;
       max-height: 96px;
      }
      .name.with-ico {
       max-height: 72px;
      }
      .description {
       white-space: normal;
       max-height: 80px;
      }
      .description.with-ico {
       max-height: 64px;
      }
     }
    }
    .grid-item:hover {
     .img {
      opacity: 1;
      filter: blur(0);
     }
     .txt {}
     .img + .txt {
      opacity: 0;
     }
    }
    @media (hover:none) {
     .grid-item:hover {
      .img {
       opacity: 0;
      }
      .txt {}
      .img + .txt {
       opacity: 1;
      }
     }
    }

   }
  }
 }
 .GridDots {
  display: block;
 }
}
@media (min-width: $mobile-menu-width+1) and (min-height: $mobile-height+1) {
 .GridBox {
  ul {
   width: 672px;
  }
 }
}