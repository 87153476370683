.fadePage-enter {
 opacity: 0;
}

.fadePage-enter-active {
 opacity: 1;
 transition: opacity 500ms ease-in 500ms, left $menu-trigger-time linear;
}

.fadePage-exit {
 opacity: 1;
}

.fadePage-exit.fadePage-exit-active {
 opacity: 0;
 transition: opacity 500ms ease-in, left $menu-trigger-time linear;
}

.fadePopup {
}

.fadePopup-enter {
 opacity: 0;
}

.fadePopup-enter-active {
 opacity: 1;
 transition: opacity 300ms;
}

.fadePopup-exit {
 opacity: 1;
}

.fadePopup-exit-active {
 opacity: 0;
 transition: opacity 300ms;
}

.fix-container {
 position: fixed;
}