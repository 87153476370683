.HeadBox {
 position: fixed;
 z-index: 0;
 width: 200px;
 height: 200px;
 left: -300px;
 top: 64px;
 opacity: 0;
 transition: opacity .8s linear 1s;

 .Head {
  width: 100%;
  height: 100%;
 }
 .dev {
  position: absolute;
  top: 0;
  width: 100%;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-family: monospace;
  padding: 0 8px;
  box-sizing: border-box;
  background: #222A;
  color: #EE3;
 }
}

#root.root-head-loaded {
 .HeadBox {
  opacity: 1;
 }
}
body.menu-active {
 .HeadBox.alpha {
  transition: opacity .5s linear;
  opacity: 0 !important;
 }
}

#headDev {
 position: fixed;
 z-index: 12000;
 left: 50%;
 top: 50%;
 background: #D00A;
 color: #FFF;
 padding: 1em 0;
 width: 500px;
 margin-left: -250px;
 margin-top: -150px;
 text-align: center;
 font-family: monospace;
}

@media (max-width: $mobile-width), (max-height: $mobile-height) {
 .HeadBox {
  display: none;
 }
}