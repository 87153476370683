.Home {
 .page {
  img {
   display: block;
   width: 96px;
   height: 96px;
  }
 }
}

@media (min-width: $mobile-width+1) and (min-height: $mobile-height+1) {
 .Home {
  .page {
   display: flex;
   align-items: center;
   justify-content: space-around;
   min-height: 100vh;
  }
 }
 #root.root-head-loaded {
  .Home {
   .page {
    display: none;
   }
  }
 }
}

@media (max-width: $mobile-width), (max-height: $mobile-height) {
 .Home {
  min-height: auto;
  height: calc(var(--vh, 1vh) * 100);
  .page {
   position: fixed;
   left: 0;
   top: 70px;
   right: 0;
   bottom: 70px;
   img {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -48px;
    margin-top: -48px;
   }
  }
 }
}
