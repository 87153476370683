.TeamPage {
}

.TeamPopup {
 .person {
  .head {
   display: flex;
   align-items: center;
   .ava {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: rgba(0, 0, 0, .2);
    overflow: hidden;
    img {
     display: block;
     width: 72px;
     height: 72px;
     opacity: 1;
    }
   }
   .title-box {
    margin-left: 16px;
    .name {
     font-weight: bold;
     font-size: 24px;
     color: rgba(255,255,255,0.80);
     letter-spacing: 0;
     line-height: 32px;
    }
    .post {
     margin-top: 8px;
     font-size: 13px;
     color: rgba(255,255,255,0.40);
     letter-spacing: 0;
     line-height: 16px;
    }
   }
  }
  .bio {
   margin-top: 24px;
   font-size: 15px;
   color: rgba(255,255,255,0.60);
   letter-spacing: 0;
   line-height: 18px;
   a {
    color: inherit;
   }
  }
 }

}

@media (min-width: $mobile-width+1) and (min-height: $mobile-height+1) {
 .TeamPopup {
  .PopupBody {
   display: flex;
   min-height: 100vh;
   align-items: center;
   justify-content: space-around;
   box-sizing: border-box;
  }
 }
}