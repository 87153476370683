.TextPage {
 .page {
  padding: $layout-padding;
  opacity: 1;
  transition: opacity 150ms linear;
  .content {
   font-size: 15px;
   color: rgba(255,255,255,0.80);
   line-height: 24px;

   h1 {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 40px;
   }
   h2 {
    font-size: 21px;
    color: rgba(255,255,255,0.60);
    line-height: 24px;
   }
   h3 {
    font-size: 17px;
    color: rgba(255,255,255,0.60);
    line-height: 24px;
   }
   a {
    color: inherit;
    text-decoration: underline;
   }
   a:hover {
    text-decoration: none;
   }
   img {
    max-width: 100%;
    height: auto;
   }
  }
 }
 .page.in-process {
  opacity: 0;
 }
}

@media (max-width: $mobile-width), (max-height: $mobile-height) {
 .TextPage {
  .page {
   background: rgba(17,17,17,.6);
   backdrop-filter: blur(6px);
   min-height: 100vh;
  }
 }
}
@media (min-width: $mobile-width+1) and (min-height: $mobile-height+1) {
 .TextPage {
  .page {
   display: flex;
   min-height: 100vh;
   align-items: center;
   justify-content: space-around;
   box-sizing: border-box;
   .ContentPage {
    background: rgba(17,17,17,.6);
    backdrop-filter: blur(6px);
    width: 696px;
    padding: 40px 24px;
    box-sizing: border-box;
   }
  }
 }
}