.Page {
 position: absolute;
 left: 0;
 top: 0;
 right: 0;
 min-height: 100vh;
 transition: left $menu-trigger-time linear;
}

.page-layer {
}

.page-layer111::before {
 content: "";
 display: block;
 height: 80px;
 background: linear-gradient(#111 0%, rgba(17, 17, 17, .8) 50%, rgba(17, 17, 17, 0) 100%);
 position: fixed;
 z-index: 80;
 left: 0;
 top: 0;
 right: 0;
}

body.popup-active {
 .page-layer {
 }
 .Page {
  overflow: hidden;
  height: 100vh;
  .PageTitle {
   opacity: 0.1;
  }
 }
}

.popup-layer111::before {
 content: "";
 display: none;
 height: 80px;
 background: linear-gradient(#111 0%, rgba(17, 17, 17, .8) 50%, rgba(17, 17, 17, 0) 100%);
 position: fixed;
 z-index: 109;
 left: 0;
 top: 0;
 right: 0;

}