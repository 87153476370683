.Popup {
 position: absolute;
 z-index: 100;
 transform: none !important;
 left: 0;
 top: 0;
 right: 0;
 min-height: 100vh;
 overflow: auto;
 background: rgba(17, 17, 17, .6);
 backdrop-filter: blur(6px);

 .PopupBody {
  padding: $layout-padding;
  transition: opacity .2s linear;
 }
 .PopupBody.in-process {
  opacity: 0;
 }
 .PopupError {
  background: #D00;
  padding: 8px 16px;
  color: #FFF;
  opacity: .8;
 }
}

@media (min-width: $mobile-width+1) and (min-height: $mobile-height+1) {
 .Popup {
  .PopupBody {
   max-width: 688px;
   margin: 0 auto;
  }
 }
}