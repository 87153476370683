.ContentPage {
 margin: 40px 0;
 font-size: 15px;
 line-height: 24px;
 color: rgba(255, 255, 255, .6);
 .title {
  font-size: 13px;
  line-height: 16px;
  color: rgba(255, 255, 255, .4);
  margin: 0 0 24px;
 }
 h1 {
  font-size: 24px;
  line-height: 32px;
  color: rgba(255, 255, 255, .8);
  margin: 24px 0 40px;
 }
 h2 {
  font-size: 17px;
  line-height: 24px;
  color: rgba(255, 255, 255, .6);
  margin: 24px 0;
 }
 p {
  margin: 24px 0;
 }
 ul, ol {
  margin: 24px 0;
  li {
   margin: 24px 0;
  }
 }
 a {
  color: inherit;
  text-decoration: underline;
 }
 a:hover {
  text-decoration: none;
 }
 table {
  margin: 24px 0;
  border-collapse: collapse;
  th, td {
   font-size: inherit;
   line-height: inherit;
   padding: 12px 12px;
   border: 1px rgba(255,255,255,.4) solid;
   vertical-align: top;
  }
 }
 .image-box {
  margin: 24px 0;
  img {
   display: block;
   max-width: 100%;
   height: auto;
  }
  .description {
   font-size: 13px;
   line-height: 16px;
   color: rgba(255, 255, 255, .4);
   margin: 24px 0 0;
  }
 }
}

