html {
 -webkit-text-size-adjust: 100%;
}

body {
 background: #333333;
 margin: 0;
 padding: 0;
 min-height: 100vh;
 font-family: 'PT Sans', sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 overscroll-behavior: none;
 position: relative;
}

.App {
 min-height: 100vh;
}

.AppError {
 color: #D00;
 padding: 16px;
 font-size: 14px;
 font-family: monospace;
}

.AppInfo {
 position: fixed;
 z-index: 3;
 top: 12px;
 right: 24px;
 font-family: 'isocteurregular', sans-serif;
 font-size: 15px;
 line-height: 16px;
 color: #ffffff;
 opacity: .8;
}
@media (max-width: $mobile-width), (max-height: $mobile-height) {
 .AppInfo {
  position: absolute;
  top: auto;
  right: auto;
  left: 24px;
  bottom: 16px;
 }
}


@media (max-width: $mobile-width), (max-height: $mobile-height) {
 html {
  height: 100%;
 }
 body {
  min-height: auto;
  height: 100%;
 }
 #root {
  height: 100%;
 }
 .App {
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
 }
}

@media print {
 .Title {
  display: none;
 }
}

@media print {
 body {
  background: none;
  padding: 0;
 }
 .AppInfo {
  display: none;
 }
}
