.AppLoadingBox {
 position: fixed;
 left: 0;
 top: 0;
 right: 0;
 bottom: 0;
 background: #111;
 transition: opacity 1.2s linear, visibility 0s linear 1.2s;
 .box {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -48px;
  margin-top: -48px;
  width: 96px;
  height: 96px;
  img.head {
   width: 96px;
   height: 96px;
  }
  .loader-box {
   position: fixed;
   top: 12px;
   left: 13px;
   color: white;
   display: flex;
   align-items: center;
  }

  .info {
   font-family: 'isocteurregular', sans-serif;
   margin-left: 13px;
   font-size: 17px;
   color: rgba(255, 255, 255, 0.60);
   .status {
    color: #FFFFFF;
   }
  }

 }
}

.AppLoaded {
 .AppLoadingBox {
  opacity: 0;
  visibility: hidden;
  .box {
  }
 }

 @media (max-width: $mobile-width), (max-height: $mobile-height) {
  .AppLoadingBox {
   .box {
   }
  }
 }

 .AppLoadingLabel {
  color: #eee;
 }

 .AppLoadingError {
  color: #d00;
  padding: 5px;
  text-align: center;
 }
}

.SpinLoader {
 font-size: 10px;
 margin: 0 auto;
 text-indent: -9999em;
 width: 22px;
 height: 22px;
 border-radius: 50%;
 background: #eeeeee;
 background: -moz-linear-gradient(left, #eeeeee 10%, rgba(255, 255, 255, 0) 42%);
 background: -webkit-linear-gradient(left, #eeeeee 10%, rgba(255, 255, 255, 0) 42%);
 background: -o-linear-gradient(left, #eeeeee 10%, rgba(255, 255, 255, 0) 42%);
 background: -ms-linear-gradient(left, #eeeeee 10%, rgba(255, 255, 255, 0) 42%);
 background: linear-gradient(to right, #eeeeee 10%, rgba(255, 255, 255, 0) 42%);
 position: relative;
 -webkit-animation: load3 1.4s infinite linear;
 animation: load3 1.4s infinite linear;
 -webkit-transform: translateZ(0);
 -ms-transform: translateZ(0);
 transform: translateZ(0);
}
.SpinLoader:before {
 width: 50%;
 height: 50%;
 background: #eeeeee;
 border-radius: 100% 0 0 0;
 position: absolute;
 top: 0;
 left: 0;
 content: '';
}
.SpinLoader:after {
 background: #111;
 width: 85%;
 height: 85%;
 border-radius: 50%;
 content: '';
 margin: auto;
 position: absolute;
 top: 0;
 left: 0;
 bottom: 0;
 right: 0;
}

@-webkit-keyframes load3 {
 0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
 }
 100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
 }
}
@keyframes load3 {
 0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
 }
 100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
 }
}
