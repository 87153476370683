.Contact {
 .page {
  .head {
   min-height: 100vh;
   display: flex;
   align-items: center;
   .img {
    text-align: center;
    width: 96px;
    margin: 0 auto;
    img {
     width: 96px;
     height: 96px;
    }
   }
  }
  .body {
   .cards-box {
    -webkit-transform: translateZ(1000px);
    width: 472px;
    height: 248px;
    position: absolute;
    left: 40px;
    bottom: 40px;
    -webkit-perspective: 1200px;
    -moz-perspective: 1200px;
    perspective: 1200px;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;

    .cards-container {
     width: 472px;
     height: 248px;
     position: relative;
     -webkit-transform-style: preserve-3d;
     -moz-transform-style: preserve-3d;
     transform-style: preserve-3d;
     -webkit-transition: .3s linear;
     -moz-transition: .3s linear;
     -o-transition: .3s linear;
     transition: .3s linear;
    }
    .card {
     box-sizing: border-box;
     padding: 32px;
     display: flex;
     position: relative;
     height: 100%;
    }
    .front, .back {
     background: rgba(255, 255, 255, 0.80);
     position: absolute;
     z-index: 2;
     left: 0;
     top: 0;
     width: 100%;
     height: 100%;
     -webkit-backface-visibility: hidden;
     -moz-backface-visibility: hidden;
     backface-visibility: hidden;

     .rotate-trigger {
      position: absolute;
      z-index: 10;
      right: 0;
      top: 0;
      width: 56px;
      height: 56px;
      border: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      @extend %svg-refresh-gray;
      opacity: .8;
      transition: all .2s linear;
      cursor: pointer;
      outline: none;
     }
     .rotate-trigger:hover {
      opacity: 1;
      transform: rotate(180deg);
     }
    }
    .front {
     -webkit-transform: rotateY(0deg);
     -moz-transform: rotateY(0deg);
     -ms-transform: rotateY(0deg);
     transform: rotateY(0deg);
     .qr {
      width: 184px;
      box-sizing: border-box;
      margin: 0;
      img {
       width: 100%;
       height: auto;
      }
     }
     ul.contacts {
      width: 192px;
      height: 100%;
      box-sizing: border-box;
      margin: 0 0 0 32px;
      padding: 0;
      list-style: none;
      li {
       font-size: 15px;
       color: rgba(0,0,0,0.80);
       letter-spacing: 0;
       line-height: 21px;
       padding-top: 21px;
       a {
        color: inherit;
        line-height: 18px;
        text-decoration: none;
        transition: all .1s linear;
        background-repeat: no-repeat;
        background-position: -3000px -3000px;
       }
       a:hover {
        color: #007aff;
       }
      }
      li:first-child {
       padding-top: 0;
      }
      .email {
       a {
        color: rgba(0,0,0,0.60);
       }
      }
      .phone {
       a {
        @extend %svg-phone-16-gray;
       }
      }
      .email {
       a {
        @extend %svg-mail-gray;
       }
      }
      .contact {
       display: none;
       a {
        @extend %svg-add-gray;
       }
      }
     }
    }
    .back {
     -webkit-transform: rotateY(-180deg);
     -moz-transform: rotateY(-180deg);
     -ms-transform: rotateY(-180deg);
     transform: rotateY(-180deg);

     .card {
      color: #333333;
      align-items: center;
      .contact {
       width: 204px;
       box-sizing: border-box;
       border-right: 1px #bbb solid;
       a {
        display: block;
        text-align: center;
        color: inherit;
        text-decoration: none;
        transition: all .1s linear;
       }
       a:hover {
        color: #007aff;
       }
      }
      .print {
       width: 204px;
       button {
        display: block;
        width: 100%;
        background: none;
        border: none;
        text-align: center;
        font: inherit;
        transition: all .1s linear;
        cursor: pointer;
       }
       button:hover {
        color: #007aff;
       }
      }
     }
    }
   }
   .cards-box.rotated .cards-container {
    transform: rotateY(180deg);
   }
  }
 }
}

@media screen {
 @media (min-width: $mobile-width+1) and (min-height: $mobile-height+1) {
  .Contact {
   min-height: 100vh;
   .page {
    position: relative;
    min-height: 100vh;
   }
  }
  #root.root-head-loaded {
   .Contact {
    .head {
     display: none;
    }
   }
  }
 }
 @media (max-width: $mobile-width), (max-height: $mobile-height) {
  .Contact {
   display: block;
   min-height: calc(var(--vh, 1vh) * 100);
   position: relative;
   backdrop-filter: blur(6px);
   .page {
    padding: 80px 24px 32px !important;
    min-height: 480px;
    box-sizing: border-box;
    .head {
     position: absolute;
     top: 48px;
     left: 0;
     right: 0;
     bottom: 296px;
     min-height: auto;
     text-align: center;
    }
    .body {
     min-height: auto;
     .cards-box {
      width: auto;
      left: 16px;
      right: 16px;
      bottom: 16px;
      .cards-container {
       width: 100%;
      }
      .card {
       padding: 0;
       display: block;
      }
      .rotate-trigger {
       display: none;
      }
      .front {
       .qr {
        display: none;
       }
       ul.contacts {
        width: 100%;
        margin: 0;
        li {
         border-bottom: 1px #bbbbbb solid;
         padding: 16px 16px 16px 32px;
         line-height: 18px;
         a {
          display: block;
          background-position: right center;
         }
        }
        .name {
         display: none;
        }
        .phone {
         a {
         }
        }
        .email {
         a {
          color: inherit;
         }
        }
        .contact {
         display: block;
        }
        .address {
         opacity: .6;
         border-bottom: 0;
        }
       }
      }
      .back {
       display: none;
      }
     }
    }
   }
  }
 }
}

@media print {
 .Contact {
  .page {
   .head {
    display: none;
   }
   .body {
    .cards-box {
     left: 50%;
     top: 50%;
     bottom: auto;
     margin-left: -236px;
     margin-top: -124px;
     transform-style: flat;
     .cards-container {
      transform-style: flat;
      transform: none !important;
      .front {
       background: none;
       border: 1px solid black;
       backface-visibility: visible;
      }
      .back {
       display: none;
       backface-visibility: visible;
      }
      .rotate-trigger {
       display: none;
      }
     }
    }
   }
  }
 }
}

@-moz-document url-prefix() {
 .Contact {
 }
}
