$mobile-width: 600px;
$mobile-height: 620px;
$mobile-menu-width: 1020px;

$menu-desktop-size: 320px;

$menu-trigger-time: 150ms;

$layout-padding: 80px 20px;

$color-firefox-layout-bg: rgba(68,68,68,.6);

