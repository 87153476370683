.PageTitle {
 transform: translateZ(80px);
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 z-index: 90;
 height: 80px;
 background: linear-gradient(#000F 0%, #0000 100%);
 padding: 15px 12px 15px 16px;
 font-family: 'isocteurregular', sans-serif;
 font-size: 17px;
 line-height: 18px;
 color: rgba(255,255,255,.8);

 .error-load {
  margin-left: 15px;
  color: #FF3B30;
 }

 .desktop {
  padding-left: 32px;
  button.i {
   @extend %svg-i;
  }
  ul {
   list-style: none;
   appearance: none;
   display: inline;
   padding: 0;
   margin: 0;
   opacity: 1;
   transition: opacity .15s linear;
   li {
    display: inline;
    padding: 0;
    margin: 0;
    font-size: 17px;
    a {
     color: rgba(255,255,255,.8);
     text-decoration: none;
     transition: color .15s linear;
     line-height: 18px;
    }
    a:hover {
     color: #FFF;
    }
    span.current {
     color: #FFF;
     font: inherit;
     line-height: 18px;
    }
   }
   li::before {
    content: " / ";
   }
  }
  .loader-box {
   position: fixed;
   top: 13px;
   left: 13px;
  }
 }
 .mobile {
  display: none;
  a {
   @extend %svg-back;
   background-position: left center;
   background-repeat: no-repeat;
   display: inline-block;
   padding-left: 32px;
   color: #fff;
   text-decoration: none;
   opacity: .8;
   transition: opacity .15s linear;
  }
  a:hover {
   opacity: 1;
  }
  .loader-box {
   display: none;
   position: fixed;
   top: 13px;
   left: 13px;
  }
 }
}
.PageTitle.in-process {
 .mobile {
  a {
   background: none;
  }
  .loader-box {
   display: block;
  }
 }
}

body.menu-active {
 .PageTitle {
  ul {
   opacity: 0;
  }
 }
}
.PopupTitle {
 transform: translateZ(1200px);
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 height: 80px;
 z-index: 110;
 background: linear-gradient(#000F 0%, #0000 100%);
 padding: 15px 12px 15px 16px;
 font-family: 'isocteurregular', sans-serif;
 font-size: 17px;
 line-height: 18px;
 a {
  @extend %svg-back;
  background-position: left center;
  background-repeat: no-repeat;
  display: inline-block;
  padding-left: 32px;
  color: #fff;
  text-decoration: none;
  opacity: .8;
  transition: opacity .15s linear;
 }
 a:hover {
  opacity: 1;
 }
 .loader-box {
  display: none;
  position: fixed;
  top: 13px;
  left: 13px;
 }
 .error-load {
  margin-left: 15px;
  color: #FF3B30;
 }
}
.PopupTitle.in-process {
 a {
  background: none;
 }
 .loader-box {
  display: block;
 }
}

@media (max-width: $mobile-width), (max-height: $mobile-height) {
 .PageTitle.mobile-dif {
  .desktop {
   display: none;
  }
  .mobile {
   display: block;
  }
 }
}
@media (min-width: $mobile-width+1) and (min-height: $mobile-height+1) {
 .PageTitle {
 }
 .PopupTitle {
 }
}

button.l-icon {
 border: none;
 padding: 0;
 cursor: pointer;
 outline: none;
 background-repeat: no-repeat;
 background-position: 12px 12px;
 background-color: transparent;
 display: block;
 position: absolute;
 left: 0;
 top: 0;
 border: 0;
 width: 48px;
 height: 48px;
 opacity: .8;
 transition: opacity .15s linear;
}
button.l-icon:hover {
 opacity: 1;
}
.l-root {
 border: none;
 padding: 0;
 cursor: pointer;
 outline: none;
 text-decoration: none;
 display: inline;
 color: rgba(255,255,255,.8);
 transition: color .15s linear;
 font: inherit;
 line-height: inherit;
}
.l-root:hover {
 color: #FFF;
}